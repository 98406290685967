import { Injectable } from '@angular/core';
import { ShowIfHttpError } from '@certemy/common';
import { SmartForm } from '@certemy/ui-components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ROLE_CODES } from 'constants/role.constants';
import { HttpService } from 'services/http/http.service';
import {
  ClientServicesOverrides,
  ClientServicesProfile,
  ProfessionalForRestoreData,
} from './client-services.interface';
import { OrgUserProfile } from 'interfaces/profile.interface';
import { RowsResponse } from 'interfaces/common.interface';
import { Country } from 'services/locations/locations.constant';
import { buildQueryParams, createQueryParam, sortItemByName } from 'utils/helpers';
import { IManagers } from 'components/profile-card/profile-card.constant';
import {
  REGISTRY_FLOW_TYPE,
  RegistryFlowSettings,
} from 'pages/organization/management/registry-settings/registry-settings.interface';

@Injectable()
export class ClientServicesService {
  constructor(private http: HttpService) {}

  @ShowIfHttpError('Cannot receive user profiles.')
  getProfiles(organizationId: number, roleId: ROLE_CODES): Observable<ClientServicesProfile[]> {
    return this.http.get(`/client-services/profiles/${organizationId}/${roleId}`);
  }

  @ShowIfHttpError('Cannot receive user profile.')
  getProfile(profileId: number): Observable<OrgUserProfile> {
    return this.http.get(`/client-services/profile/${profileId}`);
  }

  @ShowIfHttpError('Cannot receive countries for the organization.')
  getCountries(organizationId: number, params = {}): Observable<Country[]> {
    return this.http
      .get(`/client-services/organization/countries/${organizationId}`, { params })
      .pipe(map((response: RowsResponse<Country>) => sortItemByName(response.rows)));
  }

  @ShowIfHttpError('Cannot receive regions for the organization.')
  getRegions(organizationId: number, ids: number[], addParams = {}): Observable<SmartForm.FieldOption[]> {
    return this.http
      .get(`/client-services/organization/country/regions/${organizationId}`, {
        params: {
          ids,
          ...addParams,
        },
      })
      .pipe(map((response: RowsResponse<SmartForm.FieldOption>) => sortItemByName(response.rows)));
  }

  @ShowIfHttpError('Cannot receive offices for the organization.')
  getOffices(organizationId: number, ids: number[]): Observable<SmartForm.FieldOption[]> {
    return this.http
      .get(`/client-services/organization/region/offices/${organizationId}`, {
        params: {
          ids,
        },
      })
      .pipe(map((response: RowsResponse<SmartForm.FieldOption>) => sortItemByName(response.rows)));
  }

  @ShowIfHttpError('Cannot receive titles for the organization.')
  getTitles(organizationId: number): Observable<SmartForm.FieldOption[]> {
    return this.http
      .get(`/client-services/titles/${organizationId}`)
      .pipe(map((response: RowsResponse<SmartForm.FieldOption>) => sortItemByName(response.rows)));
  }

  @ShowIfHttpError('Cannot receive organization roles for the organization.')
  getOrganizationRoles(organizationId: number): Observable<SmartForm.FieldOption[]> {
    return this.http.get(`/client-services/organization-roles/${organizationId}`).pipe(
      map((response: { roles: SmartForm.FieldOption[] }) => sortItemByName(response.roles)),
      map(response => response.map(({ organizationRoleId, name }) => ({ id: organizationRoleId, name }))),
    );
  }

  @ShowIfHttpError('Cannot update user profile.')
  updateProfile(payload): Observable<OrgUserProfile> {
    return this.http.patch(`/client-services/profile`, payload);
  }

  @ShowIfHttpError('Cannot update internal id.')
  updateInternalId(profileId: string, payload): Observable<OrgUserProfile> {
    return this.http.patch(`/client-services/profile/${profileId}`, payload);
  }

  @ShowIfHttpError('Cannot get overrides for the organization.')
  getOverrides(organizationId: number): Observable<ClientServicesOverrides> {
    return this.http.get(`/client-services/organization/overrides/${organizationId}`);
  }

  @ShowIfHttpError('Cannot get registry settings for the organization.')
  getRegistrySettings(organizationId: number): Observable<RegistryFlowSettings> {
    return this.http.get(`/client-services/registry-flow-settings/organization/${organizationId}`);
  }

  @ShowIfHttpError('Cannot update registry settings for the organization.')
  updateRegistrySettings(organizationId: number, payload: { flowType: REGISTRY_FLOW_TYPE }): Observable<any> {
    return this.http.put(`/client-services/registry-flow-settings/organization/${organizationId}`, payload);
  }

  @ShowIfHttpError('Cannot get overrides for the organization.')
  getManagers(organizationId: number, filters = null, search = ''): Observable<IManagers[]> {
    const queryParams = buildQueryParams([createQueryParam('filters', filters), createQueryParam('search', search)]);
    return this.http.get(`/client-services/organization/${organizationId}/profile/possible-managers${queryParams}`);
  }

  @ShowIfHttpError('Cannot receive professionals profiles for restore.')
  getProfilesForRestore(organizationId: number): Observable<ProfessionalForRestoreData[]> {
    return this.http.get(`/client-services/restore/${organizationId}/professionals`);
  }

  @ShowIfHttpError('Cannot restore To Do or Verifier Jobs.')
  restoreTodoVerifyJobs(payload): Observable<any> {
    return this.http.post(`/client-services/restore`, payload);
  }
}
